<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form
      v-if="visible"
      ref="formRef"
      label-width="auto"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="职位名称：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="职位类型：">
        <el-radio-group v-model="form.position_type">
          <el-radio-button :label="1">全职</el-radio-button>
          <el-radio-button :label="2">兼职</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否有保底金额：">
        <el-radio-group v-model="form.is_lowest">
          <el-radio-button :label="0">无</el-radio-button>
          <el-radio-button :label="1">有</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.is_lowest" label="保底金额：" prop="lowest_amount">
        <el-input-number
          v-model.number="form.lowest_amount"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="提现打款方式：">
        <el-radio-group v-model="form.pay_type">
          <el-radio-button :label="1">线上打款</el-radio-button>
          <el-radio-button :label="2">线下打款</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);
const formRef = ref();

const defaultPositionInfo = {
  id: 0,
  name: '',
  position_type: 1,
  is_lowest: 0,
  lowest_amount: 1,
  pay_type: 1,
};

const form = ref({ ...defaultPositionInfo });

const rules = {
  name: { required: true, message: '职位名称不能为空' },
  lowest_amount: { required: true, message: '保底金额不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑职位';
  }
  return '添加职位';
});

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  if (id) {
    request.get(`/admin/engineer/position/${id}`).then((data) => {
      form.value = {
        ...data,
        lowest_amount: Number(data.lowest_amount),
      };
    });
  } else {
    form.value = { ...defaultPositionInfo };
  }
});
function onClose() {
  form.value = { ...defaultPositionInfo };
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id } = form.value;
      if (id) {
        request.patch(`/admin/engineer/position/${id}`, form.value).then(() => {
          emit('submit');
          close();
        });
      } else {
        request.post('/admin/engineer/position', form.value).then(() => {
          emit('submit');
          close();
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>
